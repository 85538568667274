import React, { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { EquipmentAcPhotoI, FileI } from "../../../../libs/interfaces";
import {
  Image,
  Button as ButtonAntd,
  Popconfirm
} from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { Grid, Paper, TextField } from "@mui/material";
import { comprimirImagen, Previews } from "../../../../libs/helpers";

export const arrPhotosEmpty: EquipmentAcPhotoI[] =
  [
    {
      order: 1,
      src: "",
      observation: "",
      uuid: "",
    },
    {
      order: 2,
      src: "",
      observation: "",
      uuid: "",
    },
    {
      order: 3,
      src: "",
      observation: "",
      uuid: "",
    }
  ];


export interface ImageComponentI {
  data: EquipmentAcPhotoI;
  onUpdate: (data: EquipmentAcPhotoI, uuid: string,) => void;
}

export const ImagenComponent = memo(({ data, onUpdate }: ImageComponentI) => {

  const [laData, setData] = useState<EquipmentAcPhotoI>({} as EquipmentAcPhotoI);
  const [files, setFiles] = useState<FileI[]>([]);
  const [continuoImg, setContinuoImg] = useState(false);

  useEffect(() => {
    // ((data))
    setData(data);
  }, [])

  useEffect(() => {
    if (continuoImg) {
      setContinuoImg(false);
      handleProcessImg();
    }
  }, [continuoImg])

  const handleObservationBlur = (e: any) => {
    onUpdate({ ...laData, observation: e.target.value }, data.uuid);
  };

  const handleFile = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      setData((elData) => ({ ...elData, src: "" }))
      onUpdate({ ...laData, src: "" }, data.uuid)
      return;
    }
    setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    setContinuoImg(true);
  }, [files]);

  const handleProcessImg = async () => {
    if (files.length === 0) {
      setData((elData) => ({ ...elData, src: "" }));
      onUpdate({ ...laData, src: "" }, data.uuid);
    } else {
      const file = files[0] as unknown as Blob;
      const imageComp = await comprimirImagen(file, 70);
      const reader = new FileReader();

      reader.readAsDataURL(imageComp as unknown as Blob);

      reader.onloadend = () => {
        setData((elData) => ({ ...elData, src: reader.result as string }));
        onUpdate({
          ...laData,
          src: reader.result as string
        }, data.uuid)
      };
    }
  }

  if (laData.src) {
    return (
      <Paper elevation={2} sx={{ m:0.5, p: 1, minWidth:"100%",}}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={5}>
            <Image
              width="100%"
              height={130}
              src={data.src}
              style={{ borderRadius: 5 }}
            />
          </Grid>
          <Grid item xs={7}>
            <Paper variant="outlined" sx={{ p: 1 }}>
              <Grid container spacing={1} >
                <Grid item xs={12}>
                  <ButtonAntd
                    type="dashed"
                    danger
                    icon={<ClearOutlined />}
                    size="small"
                    onClick={() => {
                      setData((f) => ({ ...laData, src: "" }));
                      onUpdate({ ...laData, src: "" }, data.uuid);
                      setFiles([]);
                    }}
                    style={{ marginRight: 15, position: "relative", top: 2 }}>
                      Limpiar
                    </ButtonAntd>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="observation"
                    name="observation"
                    color="info"
                    multiline
                    rows={4}
                    label="Detalle"
                    value={laData.observation || ""}
                    onChange={(e) => {
                      setData((laData) => ({ ...laData, observation: e.target.value }))
                    }}
                    onBlur={handleObservationBlur}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <Paper elevation={2} sx={{ m:0.5, p: 1, minWidth:"100%", height: 165, maxHeight: 165 }}>
      <Grid container spacing={1} >
        <Grid item xs={5}>
          <Previews handleFile={handleFile} files={files} maxFiles={1} typeFile='image/*' nameElement='Photo' noShow={true} />
        </Grid>
        <Grid item xs={7}>
          <Paper variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={1} >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="observation"
                  name="observation"
                  color="info"
                  multiline
                  rows={4}
                  label="Detalle"
                  value={laData.observation || ""}
                  onChange={(e) => {
                    setData((laData) => ({ ...laData, observation: e.target.value }))
                  }}
                  onBlur={handleObservationBlur}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  )


});