import React from "react";
import { NavLink, useLocation, useOutlet } from "react-router-dom";
import { NavbarSub } from "../../libs/shared";
import { usersGetbyToken } from "../../libs/apis";
import "../css/global.css";
import { useRouterStore } from "../../libs/stores";
import { DynamicIcon } from "../../libs/helpers";
import { COLOR_PRIMARY } from "../../libs/constants";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../css/app.css";
import { ModuleI } from "../../libs/interfaces";
import { message, Spin } from "antd";
import { LoadingPage } from "./loading";

const LazyPage = React.lazy(() => import("./Page"));

const findClosestModuleByPath = (
  modules: ModuleI[],
  path: string
): ModuleI | null => {
  let closestMatch: ModuleI | null = null;

  const searchModule = (items: ModuleI[]) => {
    for (const module of items) {
      if (path.startsWith(module.to)) {
        if (!closestMatch || module.to.length > closestMatch.to.length) {
          closestMatch = module; // Elegir el módulo con la ruta más larga que coincida
        }
      }
      if (module.children && module.children.length > 0) {
        searchModule(module.children);
      }
    }
  };

  searchModule(modules);
  return closestMatch;
};

const normalizePath = (path: string) => {
  return path.replace(/\/\d+/g, "/:id").toLowerCase();
};

const extractModuleBase = (path: string): string => {
  // Normaliza la ruta y elimina números dinámicos
  const normalizedPath = path.replace(/\/\d+/g, "/:id").toLowerCase();

  // Dividir la ruta en segmentos eliminando los vacíos
  let pathSegments = normalizedPath.split("/").filter(Boolean);

  if (pathSegments.includes("edit")) {
    pathSegments.pop();
  }

  if (pathSegments.length <= 1) {
    return `/${pathSegments[0] || ""}`; // Evita regresar solo "/"
  }

  // Si la ruta tiene solo dos segmentos (rutas cortas), tomar solo el primer segmento
  if (pathSegments.length === 2) {
    return `/${pathSegments[0]}`;
  }

  // Remover la última parte de la URL (la opción) para obtener el módulo base correcto
  return `/${pathSegments.slice(0, -1).join("/")}`;
};

export const GenericPage = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const validateModulesFromApi = useRouterStore(
    (state) => state.validateModulesFromApi
  );
  const resetOptions = useRouterStore((state) => state.resetOptions);
  const setOptions = useRouterStore((state) => state.setOptions);
  const modules = useRouterStore((state) => state.modules);
  const options = useRouterStore((state) => state.options);
  const isLoading = useRouterStore((state) => state.isLoading);
  const previousModuleBase = useRouterStore(
    (state) => state.previousModuleBase
  );
  const setPreviousModuleBase = useRouterStore(
    (state) => state.setPreviousModuleBase
  );

  React.useEffect(() => {
    const updateOptionsForRoute = async () => {
      const currentPath = location.pathname;
      const moduleBase = extractModuleBase(currentPath);

      if (previousModuleBase === moduleBase || moduleBase === "/") {
        return; // Evitar actualización innecesaria o incorrecta de raíz
      }

      setPreviousModuleBase(moduleBase);
      resetOptions(); // Limpia solo si cambia el módulo base

      await validateModulesFromApi();

      const closestModule = findClosestModuleByPath(
        modules,
        normalizePath(currentPath)
      );

      if (closestModule?.options?.length) {
        setOptions(closestModule.options);
      } else {
        setOptions([]); // Asegurar que si no hay opciones, no se agreguen innecesarias
      }
    };

    handleValidate();
    updateOptionsForRoute();
  }, [location.pathname]);

  const handleValidate = React.useCallback(async () => {
    try {
      await usersGetbyToken();
    } catch (err: any) {
      message.error(err.toString());
    }
  }, []);

  const navItems = React.useMemo(
    () =>
      options
        .filter((el) => el.show)
        .map((el) => (
          <div key={`option_${location.pathname}_${el.id}`}>
            <NavLink to={el.to} style={{textDecoration:"none"}}>
              {({ isActive }) => (
                <button className={isActive ? "button-active" : "button"}>
                  <DynamicIcon
                    icon={el.icon}
                    lib={el.iconLibrary}
                    color={COLOR_PRIMARY}
                  />
                  {el.name}
                </button>
              )}
            </NavLink>
          </div>
        )),
    [options, location.pathname]
  );

  return (
    <div className="root" key={location.pathname}>
      <NavbarSub>
        <div className="div-nav" key={location.pathname}>
          {isLoading || options.length === 0 ? (
            <div className="div-loading">
              <Spin size="small" />
            </div>
          ) : (
            navItems
          )}
        </div>
      </NavbarSub>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={location.pathname}
          timeout={100}
          classNames="page"
          unmountOnExit
        >
          <React.Suspense fallback={<LoadingPage />}>
            <LazyPage currentOutlet={currentOutlet} />
          </React.Suspense>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
