import { ModuleI, OptionI } from "../../interfaces";
import { getAllModulesByProfileId } from "../../apis";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type State = {
  isLoading: boolean;
  section: string;
  previousModuleBase: string;
  options: OptionI[];
  modules: ModuleI[];
  profileId: string;
  avatar: string;
  selectClient: number | null;
  cloneCatalogue: number | null;
  cloneOption: number | null;
  cloneTask: number | null;
  fullName: string;
  firstName: string;
};

type Actions = {
  setPreviousModuleBase: (
    previousModuleBase: State["previousModuleBase"]
  ) => void;
  setLoading: (loading: State["isLoading"]) => void;
  validateModulesFromApi: () => Promise<any>;
  setSection: (section: State["section"]) => void;
  setProfileId: (profileId: string) => void;
  setOptions: (options: OptionI[]) => void;
  setOptionsFirst: (options: OptionI[]) => void;
  setModules: (modules: ModuleI[]) => void;
  setAvatar: (avatar: string) => void;
  reset: () => void;
  resetOptions: () => void;
  resetModules: () => void;
  getModulesFromApi: () => void;
  setSelectClient: (client: number | null) => void;
  setCloneCatalogue: (clone: number | null) => void;
  setCloneOption: (clone: number | null) => void;
  setCloneTask: (task: State["cloneTask"]) => void;
  setFullName: (fullName: string) => void;
  setFirstName: (firstName: string) => void;
};

const initialState: State = {
  firstName: "",
  fullName: "",
  section: "",
  isLoading: false,
  previousModuleBase: "",
  options: [],
  modules: [],
  profileId: "",
  avatar: "",
  selectClient: null,
  cloneCatalogue: null,
  cloneOption: null,
  cloneTask: null,
};

export const useRouterStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      firstName: "",
      fullName: "",
      section: "",
      isLoading: false,
      previousModuleBase: "",
      options: [],
      modules: [],
      profileId: "",
      avatar: "",
      selectClient: null,
      cloneCatalogue: null,
      cloneOption: null,
      cloneTask: null,
      setFirstName: (firstName) => set((state) => ({ firstName })),
      setFullName: (fullName) => set((state) => ({ fullName })),
      reset: () => set(initialState),
      resetOptions: () => set((state) => ({ options: [] })),
      resetModules: () => set((state) => ({ modules: [] })),
      validateModulesFromApi: async () => {
        set({ isLoading: true });
        try {
          const token = localStorage.getItem(process.env.REACT_APP_TOKEN!);

          if (!token || !get().profileId) {
            console.error("No se encontró un token o profileId");
            set({ isLoading: false });
            return;
          }

          const { data: dataModules } = await getAllModulesByProfileId(
            get().profileId
          );

          if (dataModules) {
            const opts = Array.from(
              new Map(
                dataModules
                  .flatMap((mod) => mod.options || [])
                  .map((option) => [option.id, option]) // Usar `id` como clave única
              ).values()
            );
            set({
              modules: dataModules,
              options: opts,
            });
          } else {
            set({ modules: [], options: [] });
          }
          // return Promise.resolve(dataModules);
          return dataModules;
        } catch (error) {
          set({ modules: [], options: [] });
        } finally {
          set({ isLoading: false }); // Finaliza la carga
        }
      },
      getModulesFromApi: async () => {
        // const itRouter = localStorage.getItem(process.env.REACT_APP_TOKEN!);
        // if (!itRouter) {
        //   localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
        //   get().reset();
        //   return;
        // }

        // if (!get().profileId) return;

        // const { data: dataModules } = await getAllModulesByProfileId(
        //   get().profileId!
        // );
        // get().setModules(dataModules!);
      },
      setOptions: (options) => set({ options }),
      setModules: (modules) => set({ modules }),
      setPreviousModuleBase: (previousModuleBase) =>
        set((state) => ({ previousModuleBase })),
      setLoading: (loading) => set({ isLoading: loading }),
      setSection: (section) => set(() => ({ section })),
      setOptionsFirst: (options) => set((state) => ({ options })),
      setProfileId: (profileId) => set((state) => ({ profileId })),
      setAvatar: (avatar) => set((state) => ({ avatar })),
      setSelectClient: (selectClient) => set((state) => ({ selectClient })),
      setCloneCatalogue: (cloneCatalogue) =>
        set((state) => ({ cloneCatalogue })),
      setCloneOption: (cloneOption) => set((state) => ({ cloneOption })),
      setCloneTask: (cloneTask) => set((state) => ({ cloneTask })),
    }),
    { name: process.env.REACT_APP_TOKEN_ROUTER! }
  )
);
