import React from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Button as ButtonAntd, ConfigProvider } from "antd";
import {
  EQUIPMENT_AC_CODE,
  EQUIPMENT_BOARD_ELECTRICAL_CODE,
  EQUIPMENT_CAMS_CODE,
  EQUIPMENT_COMPUTER_CODE,
  EQUIPMENT_DOMOTICA_CODE,
  EQUIPMENT_MULTIMEDIA_CODE,
  EQUIPMENT_SECURITY_CODE,
  EQUIPMENT_UPS_CODE,
  EQUIPMENT_BOMBA_CODE,
  EQUIPMENT_KITCHEN_CODE,
  EQUIPMENT_SOLAR_CODE,
  EQUIPMENT_GENERATOR_CODE,
  MARKETING_PARAMETERS_CODE,
  LINK_FACEBOOK_CODE,
  LINK_WHATSAPP_CODE,
} from "../../libs/constants";
import {
  equipmentApi,
  getAllParameterChildrenbyParentCode,
  supplierById,
} from "../../libs/apis";
import { EquipmentI, ServiceResponseI, SupplierI } from "../../libs/interfaces";
import LogoKod from "../images/logo-kod-white.png";
import {
  ModalBodyAc,
  ModalBodyBoardElectrical,
  ModalBodyComputer,
} from "./components";
import { FacebookOutlined, WhatsAppOutlined } from "@ant-design/icons";
import noImage from "../../images/no-image.png";


const GenericExteriorPage = () => {
  const params = useParams();
  let { "*": id } = params;
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<EquipmentI | any>({} as EquipmentI);
  const [linkfb, setLinkfb] = React.useState("");
  const [linkwhatsapp, setLinkwhatsapp] = React.useState("");
  const [showMarketing, setShowMarketing] = React.useState(false);
  const [dataSupplier, setDataSupplier] = React.useState<SupplierI | any>(
    {} as SupplierI
  );

  React.useEffect(() => {
    if (id) {
      handleInformation();
    } else {
      //   handleMarketing();
    }
  }, []);

  const handleInformation = async () => {
    try {
      const params = new URLSearchParams();
      id && params.append("id", id.toString());

      const { data } = await equipmentApi.get<ServiceResponseI<any>>(
        `/exterior`,
        { params }
      );
      if (!data) return;
      setData(data.data!);
      setLoading(false);
      handleMarketing();
      if (data.data.supplierId) handleMarketingSupplier(data.data.supplierId);
    } catch (err: any) {
      setLoading(false);
      throw new Error(err);
    }
  };

  const handleMarketingSupplier = async (supplierId: number) => {
    try {
      if (supplierId === null) return;
      const { data: dataSupplier } = await supplierById(supplierId);
      if (!dataSupplier) return;
      setDataSupplier(dataSupplier);
      if(dataSupplier.marketing)setShowMarketing(true);
    } catch (err: any) {
      setLoading(false);
      throw new Error(err);
    }
  };

  const handleMarketing = async () => {
    try {
      const { data: dataParameters } =
        await getAllParameterChildrenbyParentCode(MARKETING_PARAMETERS_CODE);
      if (!dataParameters) return;
      const fb = dataParameters!.find(
        (el) => el.code === LINK_FACEBOOK_CODE
      )!.value;
      const ws = dataParameters!.find(
        (el) => el.code === LINK_FACEBOOK_CODE
      )!.value;
      setLinkfb(fb);
      setLinkwhatsapp(ws);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCloseMarketing = () => {
    setShowMarketing(false);
  };

  if (loading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Paper
        sx={{
          p: 2,
          position: "relative",
          borderRadius: 0,
          maxHeight: `100vh`,
          minHeight: `100vh`,
          height: `100vh`,
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        {showMarketing? (
          <Box
            sx={{
              width: 150,
              height: 200,
              position: "absolute",
              right: 5,
              top: 5,
              zIndex: 1601,
              bgcolor: "white",
              borderRadius: "5px",
              border: "1px dashed grey",
            }}
          >
            <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
              <ButtonAntd
                type="primary"
                danger
                style={{ position: "absolute", top: 2, right:2, width: 20, height: 25 }}
                onClick={handleCloseMarketing}
              >
                X
              </ButtonAntd>
              <img height={198} src={dataSupplier.srcMarketing ?? noImage} />
              <Box sx={{ position: "absolute", bottom: 0, left: 0, width:149, display: "flex", justifyContent: "center", gap: 1, alignItems: "center" }}>
                <ButtonAntd
                  shape="circle"
                  color="primary"
                  type="primary"
                  target="_blank"
                  icon={<FacebookOutlined />}
                  href={dataSupplier.linkMarketingFb}
                />
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        defaultBg: "#32CD32",
                        defaultActiveBg: "#32CD32",
                        defaultHoverBg: "#32CD32",
                        colorTextBase: "white",
                        colorText: "white",
                      },
                    },
                  }}
                >
                  <ButtonAntd
                    shape="circle"
                    href={dataSupplier.linkMarketingWs}
                    target="_blank"
                    icon={<WhatsAppOutlined color="white" />}
                  />
                </ConfigProvider>
              </Box>
            </Box>
          </Box>
        ) : null}
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            height: 80,
            overflowY: "hidden",
            overflowX: "hidden",
            zIndex: 1101,
            top: 0,
            left: 0,
            background: "linear-gradient(90deg, #032d81,#8b9de5,#ffffff)",
          }}
        >
          <img
            src={LogoKod}
            style={{
              width: 50,
              position: "absolute",
              top: 7,
              left: 5,
              zIndex: 1101,
            }}
          />
          <Typography
            variant="h3"
            display="block"
            gutterBottom
            sx={{
              position: "absolute",
              top: 9,
              left: 60,
              color: "white",
              zIndex: 1101,
            }}
          >
            KOD
          </Typography>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              position: "absolute",
              top: 40.5,
              left: 61,
              color: "white",
              fontWeight: "bolder",
              zIndex: 1101,
            }}
          >
            Control de Activos
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              right: 0,
              width: 100,
              height: 80,
              zIndex: 1101,
            }}
          >
            <ButtonAntd
              shape="circle"
              color="primary"
              type="primary"
              target="_blank"
              icon={<FacebookOutlined />}
              href={linkfb}
            />
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: "#32CD32",
                    defaultActiveBg: "#32CD32",
                    defaultHoverBg: "#32CD32",
                    colorTextBase: "white",
                    colorText: "white",
                  },
                },
              }}
            >
              <ButtonAntd
                shape="circle"
                href={linkwhatsapp}
                target="_blank"
                icon={<WhatsAppOutlined color="white" />}
              />
            </ConfigProvider>
          </Box>
        </Box>
        <Grid
          container
          justifyContent="center"
          spacing={1}
          direction="row"
          sx={{
            marginTop: 8,
            maxHeight: `calc(100vh - 110px)`,
            minHeight: `calc(100vh - 110px)`,
            height: `calc(100vh - 30px)`,
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {data.typeEquipmentCode === EQUIPMENT_AC_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_BOARD_ELECTRICAL_CODE ? (
            <ModalBodyBoardElectrical data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_DOMOTICA_CODE ? (
            <ModalBodyBoardElectrical data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_COMPUTER_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_UPS_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_SECURITY_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_CAMS_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_MULTIMEDIA_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_SOLAR_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_GENERATOR_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_BOMBA_CODE ? (
            <ModalBodyAc data={data} />
          ) : data.typeEquipmentCode === EQUIPMENT_KITCHEN_CODE ? (
            <ModalBodyAc data={data} />
          ) : (
            <ModalBodyAc data={data} />
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default GenericExteriorPage;
