import { useQuery } from "@tanstack/react-query";
import { parametersApi } from "./axios";
import {
  DataI,
  MigrationsI,
  ParameterI,
  ParameterSystemCredentialsMailI,
  PropsI,
  ServiceResponseI,
} from "../../interfaces";
import { ValidateCurrentToken, LogoutSystem } from "../../helpers";
import { AxiosError } from "axios";
import {
  TYPE_MATERIAL_CODE,
  TYPE_IDENTIFICATION_CODE,
  METHOD_PAYMENT_CODE,
  TYPE_PAYMENT_CODE,
  TYPE_ACCOUNT_BANK_CODE,
  CONTACT_TYPES_MAIL_CODE,
  CONTACT_TYPES_PHONE_CODE,
  COLLECTION_TYPES_CODE,
  SUBSCRIPTION_TYPES_CODE,
  PROFILE_TYPES_CODE,
  CLIENT_TYPES_CODE,
  EQUIPMENT_STATUSES_CODE,
} from "../../constants";

const getAllParameters = async ({
  pagination,
  sorting,
  globalFilter,
}: PropsI): Promise<ServiceResponseI<DataI<ParameterI>>> => {
  try {
    const validateToken = ValidateCurrentToken();

    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("page", (pagination!.pageIndex + 1).toString());
    params.append("limit", pagination!.pageSize.toString());
    params.append("search", globalFilter ?? "");
    params.append("sorting", JSON.stringify(sorting ?? []));

    //headers: { 'Authorization': validateToken
    const { data } = await parametersApi.get<
      ServiceResponseI<DataI<ParameterI>>
    >("", { params, headers: { Authorization: validateToken } });
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;

    if (obj?.status === 401) {
      LogoutSystem();
    }

    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypeMaterial = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", TYPE_MATERIAL_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypeIdentification = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", TYPE_IDENTIFICATION_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypePayment = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", TYPE_PAYMENT_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypeAccountBank = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", TYPE_ACCOUNT_BANK_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterMethodPayment = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", METHOD_PAYMENT_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllContactsTypeMail = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", CONTACT_TYPES_MAIL_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllContactsTypePhone = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", CONTACT_TYPES_PHONE_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllCollectionTypes = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", COLLECTION_TYPES_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllSubscriptionTypes = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", SUBSCRIPTION_TYPES_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllEquipmentStatuses = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", EQUIPMENT_STATUSES_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypeProfile = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", PROFILE_TYPES_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterTypeClient = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", CLIENT_TYPES_CODE);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getAllParameterSystemCredentialsMail = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await parametersApi.get<
      ServiceResponseI<ParameterSystemCredentialsMailI>
    >("/get-system-credentials-mail", {
      headers: { Authorization: validateToken },
    });

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const getParameterByCode = async (code: string) => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", code);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI>>(
      "/get-by-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((err as Error).message);
  }
};

export const parameterPut = async <T>(
  id: number,
  updateForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await parametersApi.put<ServiceResponseI<string>>(
      `/${id}`,
      updateForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const parameterPutMassive = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await parametersApi.put<ServiceResponseI<string>>(
      "/massive",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const parameterPutMassiveCode = async <T>(
  getForms: T
): Promise<ServiceResponseI<string>> => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");
    const { data } = await parametersApi.put<ServiceResponseI<string>>(
      "/massive-by-code",
      getForms,
      { headers: { Authorization: validateToken } }
    );
    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllParameterChildrenbyParentCode = async (code: string) => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const params = new URLSearchParams();

    params.append("code", code);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params, headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const getAllParameterNoAuthChildrenbyParentCode = async (
  code: string
) => {
  try {
    // const validateToken = ValidateCurrentToken();
    // if (!validateToken) throw new Error('TOKEN INVALIDO');

    const params = new URLSearchParams();

    params.append("code", code);

    const { data } = await parametersApi.get<ServiceResponseI<ParameterI[]>>(
      "/all-children-by-parent-code",
      { params }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const renewWsService = async () => {
  try {
    const validateToken = ValidateCurrentToken();
    if (!validateToken) throw new Error("TOKEN INVALIDO");

    const { data } = await parametersApi.get<ServiceResponseI<string>>(
      "/renew-ws",
      { headers: { Authorization: validateToken } }
    );

    return data;
  } catch (err) {
    const obj = (err as AxiosError).response;
    if (obj?.status === 401) {
      console.error(obj.statusText);
      LogoutSystem();
    }
    throw new Error((obj?.data as Error).message);
  }
};

export const useParameters = ({
  sorting,
  pagination,
  globalFilter,
}: PropsI) => {
  const parametersQuery = useQuery(
    ["parameters", { pagination, sorting, globalFilter }],
    () => getAllParameters({ pagination, sorting, globalFilter }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  return {
    parametersQuery,
  };
};
