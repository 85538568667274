import React from "react";
import { Box, Backdrop } from "@mui/material"
import loadingPicture from "../images/wait-kod.gif"

export const LoadingPage = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeContent: "center",
                background:"rgba(205, 205, 205, 0)",
            }}>
                <img src={loadingPicture} width="100%" style={{width:400, height:300, borderRadius:5, background:'white',}}/>
        </div>
    )
}

export const LoadingFullPage = () => {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "grid",
                placeContent: "center",
                background:"rgba(205, 205, 205, 0)",
            }}>
                <img src={loadingPicture} width="100%" style={{width:500, height:500, borderRadius:5, background:'white',}}/>
        </div>
    )
}