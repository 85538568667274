import React, { ChangeEvent, memo, useEffect, useState } from "react";
import { ConsumableI, StoreConsumableI } from "../../../../libs/interfaces";
import { Button as ButtonAntd, Input, Popconfirm, Select } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
const { Option } = Select;

interface ConsumablePropsI {
  data: ConsumableI;
  onUpdate: (data: ConsumableI, uuid: string) => void;
  onDelete: (uuid: string) => void;
  uuid: string;
  storeConsumables: StoreConsumableI[];
}

export const ConsumableItem = memo(
  ({ data, onUpdate, onDelete, uuid, storeConsumables }: ConsumablePropsI) => {
    const [value, setValue] = useState<ConsumableI>({
      name: "",
      startDate: "",
      endingDate: "",
      uuid,
      periodValue: 0,
      periodOption: "",
      type: "",
      typeId: null,
    });

    useEffect(() => {
      setValue(data);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue({ ...value, [e.target.id]: e.target.value });
    };

    const handleBlur = (e: any) => {
      const obj = { ...value, [e.target.id]: e.target.value };
      setValue(obj);
      onUpdate(obj, uuid);
    };

    const handleDate = (e: ChangeEvent<HTMLInputElement>) => {
      const obj = { ...value, [e.target.id]: e.target.value };
      if (e.target.value && value.periodOption && value.periodValue) {
        const date_ = addTimeToDate(
          e.target.value,
          Number(value.periodValue),
          value.periodOption
        );
        obj.endingDate = date_;
      }
      setValue(obj);
      onUpdate(obj, uuid);
    };

    const handleDateCambio = (e: ChangeEvent<HTMLInputElement>) => {
      const obj = { ...value, [e.target.id]: e.target.value };
      setValue(obj);
      onUpdate(obj, uuid);
    };

    const selectAfter = (
      <Select
        style={{ width: 100 }}
        value={value.periodOption || ""}
        onChange={(value_) => {
          if (value.startDate && value.periodValue) {
            const date_ = addTimeToDate(
              value.startDate,
              Number(value.periodValue),
              value_
            );
            const obj = { ...value, periodOption: value_, endingDate: date_ };
            setValue(obj);
            onUpdate(obj, uuid);
          } else {
            const obj = { ...value, periodOption: value_ };
            setValue(obj);
            onUpdate(obj, uuid);
          }
        }}
      >
        <Option value="Mes(es)">Mes(es)</Option>
        <Option value="Año(s)">Año(s)</Option>
      </Select>
    );

    function addTimeToDate(dateStr: string, amount: number, unit: string) {
      const date = new Date(dateStr);

      if (isNaN(date.getTime())) {
        throw new Error(
          "Fecha inválida. Asegúrate de que la fecha esté en formato YYYY-MM-DD."
        );
      }

      if (unit === "Mes(es)") {
        date.setMonth(date.getMonth() + amount);
      } else if (unit === "Año(s)") {
        date.setFullYear(date.getFullYear() + amount);
      } else {
        throw new Error('Unidad no válida. Usa "months" o "years".');
      }
      return date.toISOString().split("T")[0];
    }

    return (
      <Box
        sx={{
          width: "100%",
          p: 2,
          border: "1px solid gainsboro",
          borderRadius: 2,
          position: "relative",
          boxShadow: "0px 10px 18px 0px rgba(0,0,0,0.1)",
          background: "white",
        }}
      >
        <div style={{ position: "absolute", bottom: 0, right: 10 }}>
          <Popconfirm
            title="Eliminar Consumible"
            description="Seguro desea eliminar este Consumible?"
            onConfirm={() => onDelete(uuid)}
            okText="Si"
            cancelText="No"
          >
            <ButtonAntd
              type="text"
              icon={<DeleteFilled />}
              size="small"
              danger
            />
          </Popconfirm>
        </div>

        <Grid container spacing={1.5} sx={{ mb: 1 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="type"
              name="type"
              color="info"
              sx={{ fontSize: 18 }}
              multiline
              label="Tipo"
              value={value.type || ""}
              onChange={(e) => {
                const fill = storeConsumables.find(
                  (el) => el.name === e.target.value
                );
                const obj = {
                  ...value,
                  type: e.target.value,
                  typeId: fill!.id,
                };
                setValue(obj);
                onUpdate(obj, uuid);
              }}
              select
            >
              {storeConsumables.map((el) => (
                <MenuItem key={el.id} value={el.name}>
                  {el.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Input
              addonBefore={
                <p style={{ minWidth: 70 }}>
                  <span style={{ position: "relative", top: 7 }}>Periódo</span>
                </p>
              }
              addonAfter={selectAfter}
              style={{ width: "100%" }}
              value={value.periodValue || ""}
              onChange={handleChange}
              name="periodValue"
              id="periodValue"
              type="number"
              placeholder="1"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="startDate"
              name="startDate"
              color="info"
              type="date"
              label="Fecha Instalación"
              value={value.startDate || ""}
              onChange={handleDate}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="endingDate"
              name="endingDate"
              color="info"
              type="date"
              label="Fecha de Cambio"
              value={value.endingDate || ""}
              onChange={handleDateCambio}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              id="name"
              name="name"
              color="info"
              sx={{ fontSize: 12 }}
              multiline
              label="Detalle"
              value={value.name || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              minRows={2}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);
