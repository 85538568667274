import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ReplaceListComponent = React.lazy(() => import("../../../../../components/replace/components/list/page"));
const ReplaceAddComponent = React.lazy(() => import("../../../../../components/replace/components/add/page"));
const ReplaceAssignComponent = React.lazy(() => import("../../../../../components/replace/components/assign/page"));
export const replaceRoute = [
    {
        path: 'list',
        element: <ReplaceListComponent />
    },
    {
        path: 'add',
        element: <ReplaceAddComponent />
    },
    {
        path: 'assign',
        element: <ReplaceAssignComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/replaces/add" />
    },
    {
        path: 'edit/:id',
        element: <ReplaceAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/replaces/list" />
    },
];