import React from "react";
import {
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { drawerWidth } from "../appbar/appbar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../css/drawer.css";
import { DynamicIcon } from "../../../libs/helpers";
import { ModuleI, OptionI } from "../../../libs/interfaces";
import { Button as ButtonAntd } from "antd";
import { useRouterStore } from "../../../libs/stores";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  NAVBAR_HEIGHT,
} from "../../../libs/constants";
import { useWidth } from "../../../libs/hooks";
import clsx from "clsx";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: 0,
  justifyContent: "center",
  height: `57px`,
  minHeight: `57px`,
  maxHeight: `57px`,
  backgroundColor: COLOR_PRIMARY,
  color: "white",
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut, // Cambio de sharp a easeInOut
    duration: theme.transitions.duration.standard, // Duración estándar más suave
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut, // Cambio aquí también
    duration: theme.transitions.duration.standard,
  }),
  overflowX: "hidden",
  width: `calc(78px + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(78px + 1px)`,
  },
});

const DrawerEl = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 998,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)", // Transición más suave
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)", // Sombra para dar más profundidad
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface PropsDrawerI {
  open: boolean;
  handleClose: () => void;
}

interface PropsI {
  data: ModuleI;
  padding?: number;
  open: boolean;
}

interface TokenUserPropsI {
  id: number;
  fullName: string;
  lastName: string;
  firstName: string;
  area: string;
  mail: string;
  type: string;
  token: string;
  modules: Array<ModuleI>;
  profileId: number;
}

interface TokenPropsI {
  user: TokenUserPropsI;
}

export const Drawer = ({ open, handleClose }: PropsDrawerI) => {
  const theme = useTheme();
  const width = useWidth();
  const navigate = useNavigate();
  const modules = useRouterStore((state) => state.modules);

  // const handleNavigate = () => {
  //   navigate("/dashboard");
  //   handleClose();
  // };

  const BodyDrawer = () => (
    <>
      <DrawerHeader>
        <ButtonAntd
          type="dashed"
          style={{
            position: "relative",
            top: -1,
          }}
          onClick={handleClose}
          icon={
            theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )
          }
          size="small"
        />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{ m: 0 }}>
            KOD
          </Typography>
          <Typography variant="caption" gutterBottom sx={{ mt: -0.6 }}>
            Control de Activos
          </Typography>
        </Box>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          padding: "4px 0px 0px 0px",
          width: width === "xs" ? drawerWidth : "auto",
          overflowY: "auto",
          overflowX: "hidden",
          transition: "all .2s ease-in-out",
          height: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
          minHeight: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
          maxHeight: `calc(100vh - ${
            Number(width === "xs" ? 130 : NAVBAR_HEIGHT) + 2
          }px)`,
          // Ocultar la scrollbar en navegadores WebKit (Chrome, Safari)
          "&::-webkit-scrollbar": {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
          },
        }}
      >
        {modules.map((el) => (
          <MemoizedDrawerItem key={`module_${el.id}`} data={el} open={open} />
        ))}
      </List>
    </>
  );

  if (width === "xs") {
    return (
      <MuiDrawer open={open} onClose={handleClose}>
        <BodyDrawer />
      </MuiDrawer>
    );
  }
  return (
    <DrawerEl variant="permanent" open={open}>
      <BodyDrawer />
    </DrawerEl>
  );
};

export const DrawerItem = ({ data, open }: PropsI) => {
  const {
    id,
    name,
    nameSort,
    icon,
    iconLibrary,
    iconActive,
    iconActiveLibrary,
    children,
  } = data;

  const location = useLocation();

  const [openOption, setOpenOption] = React.useState(false);

  const onClickOption = () => setOpenOption((state) => !state);

  const isChildActive = children?.some((child) =>
    location.pathname.includes(child.to)
  );

  if (!children) {
    return <MemoizedDrawerItemChildren data={data} padding={0} open={open} />;
  }

  if (children.length === 0) {
    return <MemoizedDrawerItemChildren data={data} padding={0} open={open} />;
  }

  return (
    <>
      <ListItem
        key={`${id}_${name}_${nameSort}`}
        disablePadding
        divider
        className={clsx("listItem", {
          ["listItemActive"]: isChildActive,
          ["listItemOpen"]: openOption && !isChildActive,
        })}
      >
        <ListItemButton
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            height: 55,
            textDecoration: "none",
            transition: "all 0.3s ease-in-out",
          }}
          onClick={onClickOption}
        >
          <ListItemIcon>
            <DynamicIcon
              icon={openOption ? iconActive : icon}
              lib={openOption ? iconActiveLibrary : iconLibrary}
              color={openOption ? isChildActive ? "#fff" : "navy" : isChildActive ? "#fff" : COLOR_PRIMARY}
              size={open ? 20 : 16}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            sx={{
              fontSize: open ? 12 : 10,
              fontWeight: open ? "bolder" : 600,
              position: "relative",
              left: open ? -30 : -0,
              textDecoration: "none",
              transition: "all 0.2s ease-in-out",
            }}
            primary={nameSort}
          />
        </ListItemButton>
      </ListItem>
      <Collapse in={openOption} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((el) => (
            <MemoizedDrawerItem
              key={`${el.id}_${el.code}`}
              data={el}
              padding={0.5}
              open={open}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MemoizedDrawerItem = React.memo(DrawerItem);

export const DrawerItemChildren = ({ data, padding, open }: PropsI) => {
  const {
    id,
    to,
    name,
    nameSort,
    icon,
    iconLibrary,
    iconActive,
    iconActiveLibrary,
    options,
    parentModuleId,
  } = data;

  return (
    <ListItem
      key={`${id}_${name}_${nameSort}`}
      disablePadding
      sx={{
        pl: padding,
        borderBottom: options ? "1px solid gainsboro" : "none",
        background: parentModuleId && options ? "#E5E7E9" : "white",
        height: parentModuleId && options ? 50 : 55,
      }}
    >
      <NavLink to={to!} style={{ width: "100%", textDecoration: "none" }}>
        {({ isActive, isPending }) => (
          <div className={isActive ? "navlinkA" : "navlink"}>
            <ListItemButton className="list-item-button" selected={isActive}>
              <ListItemIcon>
                <DynamicIcon
                  icon={isActive ? iconActive : icon}
                  lib={isActive ? iconActiveLibrary : iconLibrary}
                  color={isActive ? "#fff" : COLOR_PRIMARY}
                  size={open ? 20 : 16}
                  style={{ position: "relative", left: open ? 0 : 3 }}
                />
              </ListItemIcon>
              <ListItemText
                // className="list-item-text"
                disableTypography
                sx={{
                  fontSize: open ? 12 : 10,
                  fontWeight: open ? "bolder" : 600,
                  position: "relative",
                  left: open ? -30 : 0,
                  textDecoration: "none",
                  // transition:"all 0.2s ease-in-out"
                }}
                primary={nameSort}
              />
            </ListItemButton>
          </div>
        )}
      </NavLink>
    </ListItem>
  );
};

const MemoizedDrawerItemChildren = React.memo(DrawerItemChildren);
