import React from "react";
import {
    Navigate,
} from "react-router-dom";

const ConsumableListComponent = React.lazy(() => import("../../../../../components/consumables/components/list/page"));
const ConsumableAddComponent = React.lazy(() => import("../../../../../components/consumables/components/add/page"));
const ConsumableAssignComponent = React.lazy(() => import("../../../../../components/consumables/components/assign/page"));

export const consumableRoute = [
    {
        path: 'list',
        element: <ConsumableListComponent />
    },
    {
        path: 'add',
        element: <ConsumableAddComponent />
    },
    {
        path: 'assign',
        element: <ConsumableAssignComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/store/consumables/add" />
    },
    {
        path: 'edit/:id',
        element: <ConsumableAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/store/consumables/list" />
    },
];