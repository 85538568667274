import { useQuery } from "@tanstack/react-query";
import { equipmentStoreReplaceApi } from "./axios";
import {
    ServiceResponseI,
    ProfileTaskI,
    TaskI,
    StoreReplaceI,
    EquipmentStoreReplaceI,
    EquipmentI,
    CatalogueI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



export const getAllStoreReplacesByEquipmentReference = async (reference:string):Promise<ServiceResponseI<StoreReplaceI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('reference', reference);

        const { data } = await equipmentStoreReplaceApi.get<ServiceResponseI<StoreReplaceI[]>>(
            `/all-store-replace-by-equipment-reference`, 
            {    params,
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllStoreReplacesByEquipmentId = async (id:number):Promise<ServiceResponseI<StoreReplaceI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentStoreReplaceApi.get<ServiceResponseI<StoreReplaceI[]>>(
            `/all-store-replace-by-equipment-id/${id}`, 
            {    
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllEquipmentsByStoreReplaceId = async (id:number):Promise<ServiceResponseI<CatalogueI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await equipmentStoreReplaceApi.get<ServiceResponseI<CatalogueI[]>>(
            `/all-equipments-by-store-replace-id/${id}`, 
            {   
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getAllEquipmentStoreReplaceByEquipmentId = async (equipmentId:number):Promise<ServiceResponseI<EquipmentStoreReplaceI[]>> => {

    try {
        // const validateToken = ValidateCurrentToken();
        // if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await equipmentStoreReplaceApi.get<ServiceResponseI<EquipmentStoreReplaceI[]>>(
            `/all-by-equipment-id/${equipmentId}`, 
           );
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentStoreReplacePost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentStoreReplaceApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentStoreReplacePostByReference = async<T>(reference:string, getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('reference', reference);

        const { data } = await equipmentStoreReplaceApi.post<ServiceResponseI<string>>("/by-reference", getForms, { params, headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentStoreReplacePut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentStoreReplaceApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const equipmentStoreReplacebyId = async (id: number): Promise<ServiceResponseI<ProfileTaskI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await equipmentStoreReplaceApi.get<ServiceResponseI<ProfileTaskI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}
