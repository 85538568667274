import React, { useCallback, useState } from "react";
import { COLOR_PRIMARY, URL_BACKEND } from "../../../constants";
import { useRouterStore } from "../../../stores";
import { Dropdown, MenuProps, Avatar } from "antd";
import { CloseCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DialogCloseSession } from "../../../../root/components/dialog";
import "../../css/user-avatar.css";
import { Person } from "@mui/icons-material";

export const UserAvatar = () => {
  const navigate = useNavigate();
  const firstName = useRouterStore((state) => state.firstName);
  const avatar = useRouterStore((state) => state.avatar);
  const [openSession, setOpenSession] = useState(false);

  const items: MenuProps["items"] = [
    {
      style: {
        backgroundColor: "white",
        color: "#191970",
        margin: "5px",
        textAlign: "center",
        height: "30px",
      },
      label: (
        <p
          onClick={() => {
            navigate("/user/information");
          }}
          style={{ fontSize: "14px", fontWeight: "bold", height: "10px" }}
        >
          {firstName}
        </p>
      ),
      key: `option-one-avatar`,
      icon: <Person />,
    },
    {
      style: {
        backgroundColor: COLOR_PRIMARY,
        color: "white",
        margin: "5px",
        textAlign: "left",
        height: "30px",
      },
      label: (
        <p
          onClick={() => {
            navigate("/user/information");
          }}
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            height: "10px",
          }}
        >
          Perfil
        </p>
      ),
      key: `option-two-avatar`,
      icon: <SettingOutlined />,
    },
    {
      style: {
        backgroundColor: "crimson",
        color: "white",
        margin: "5px",
        textAlign: "left",
        height: "30px",
      },
      label: (
        <p
          onClick={() => {
            setOpenSession(true);
          }}
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            height:"10px"
          }}
        >
          Cerrar Sesión
        </p>
      ),
      key: "option-three-avatar",
      icon: <CloseCircleOutlined />,
    },
  ];

  const handleCloseSession = useCallback(() => {
    setOpenSession(false);
  }, [openSession]);

  return (
    <div>
      <DialogCloseSession open={openSession} handleClose={handleCloseSession} />
      <Dropdown
        destroyPopupOnHide
        menu={{ items }}
        trigger={["click"]}
        overlayStyle={{ width: 170, margin: "5px" }}
      >
        <Avatar className="avatar-element" size="large" gap={4} src={avatar}>
          {firstName?.toString().charAt(0) ?? ""}
        </Avatar>
      </Dropdown>
    </div>
  );
};
